import React from "react";
import {graphql} from "gatsby";
import Layout from "../layout/layout";
import OpenConfigurator from "../components/general/open-configurator";
import { GatsbyImage } from "gatsby-plugin-image";
import Grid from "@material-ui/core/Grid";
import SEO from "../components/seo";

const GuardrailDetailsTemplate = ({ data }) => {
  const foundImage = data.guardrailImages.nodes
    .find(node => node.base.startsWith(data.guardrailData.frontmatter.img));

  if(!foundImage) {
    throw Error(`Image ${data.guardrailData.frontmatter.img} not found`);
  }

  return (
    <Layout>
      <SEO title={data.guardrailData.frontmatter.title}/>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <GatsbyImage image={foundImage.childImageSharp.gatsbyImageData} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div dangerouslySetInnerHTML={{ __html: data.guardrailData.html }}></div>
          <OpenConfigurator />
        </Grid>
      </Grid>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($railguardType: String!) {
    guardrailData: markdownRemark(frontmatter: { type: { eq: $railguardType } }) {
      html
      frontmatter {
        type
        title
        img
      }
    }
    
    guardrailImages: allFile(filter: {relativeDirectory: {eq: "guardrails"}}) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  }
`

export default GuardrailDetailsTemplate;